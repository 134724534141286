export default {
  methods: {
    getCategoryLabel (index) {
      switch (index) {
        case 'default':
          return 'Core'
        case 'elite':
          return 'SG Series'
      }
    }
  }
}
