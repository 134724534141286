var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('vue-data-table',{attrs:{"cols":[
      {
        header: 'Name',
        value: 'name',
      },
      {
        header: 'SKU',
        value: 'sku'
      },
      {
        header: 'Price',
        value: 'price'
      },
      {
        header: 'Categories',
        value: 'categories'
      },
      {
        header: 'In Stock?',
        value: 'in_stock'
      },
      {
        header: 'Added At',
        value: 'created_at',
        sort: true
      },
      {
        header: 'Last Modified',
        value: 'updated_at',
        sort: true
      },
      {
        header: 'Live At',
        value: 'live_at',
        sort: true
      },
      {
        header: 'Actions',
        value: 'actions'
      }
    ],"path":"products","request-params":{
      is_part: false
    }},scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('vue-button',{attrs:{"icon":['fas', 'plus-circle']},on:{"click":function($event){return _vm.$router.push({ name: 'products.new' })}}},[_vm._v(" Create ")])]},proxy:true},{key:"item.price",fn:function(row){return [_c('div',{staticClass:"w-full flex justify-between"},[_c('span',[_vm._v("£")]),_c('span',[_vm._v(_vm._s(row.price.toFixed(2)))])])]}},{key:"item.categories",fn:function(row){return [(row.extra_data && row.extra_data.categories && Object.values(row.extra_data.categories).length > 0)?_c('div',{staticClass:"flex flex-col whitespace-nowrap"},_vm._l((row.extra_data.categories),function(value,index){return _c('div',{key:index},[_c('font-awesome-icon',{class:{
              'text-green-700': value,
              'text-red-700': !value
            },attrs:{"icon":['fas', value ? 'check-circle' : 'times-circle'],"size":"lg","fixed-width":""}}),_c('span',[_vm._v(_vm._s(_vm.getCategoryLabel(index)))])],1)}),0):_c('span',[_vm._v(" N/A ")])]}},{key:"item.in_stock",fn:function(row){return [_c('font-awesome-icon',{class:{
          'text-green-700': row.extra_data && row.extra_data.visible,
          'text-red-700': !row || !row.extra_data || (row && row.extra_data && !row.extra_data.visible)
        },attrs:{"icon":['fas', row.extra_data && row.extra_data.visible ? 'check-circle' : 'times-circle'],"size":"lg","fixed-width":""}})]}},{key:"item.created_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.created_at))+" ")]}},{key:"item.updated_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.updated_at))+" ")]}},{key:"item.live_at",fn:function(row){return [_vm._v(" "+_vm._s(row.live_at ? _vm.$momentDateTimeHuman(row.live_at) : 'Not Published')+" ")]}},{key:"item.actions",fn:function(row){return [_c('vue-link',{staticClass:"inline-flex cursor-pointer",attrs:{"to":{ path: ("/products/" + (row.id)) },"icon":['fas', 'edit'],"aria-label":("Edit Product: " + (row.name)),"text":"","sm":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }